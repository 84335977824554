import React, {useContext, useEffect, useState} from "react";
import {useParams, useHistory} from "react-router";
import GroupController, {GroupData} from "../services/GroupController";
import PaymentController, {PaymentData, PaymentShare} from "../services/PaymentController";
import {ApplicationContext} from "../ApplicationContext";
import BigNumber from 'bignumber.js';
import {MemberData} from "../services/UserController";
import {DiffRemovedIcon} from "@primer/octicons-react";

interface PaymentComponentParams {
    id: string;
}

const PaymentPageComponent = () => {
    const history = useHistory();
    const {id} = useParams<PaymentComponentParams>();
    const appContext = useContext(ApplicationContext);
    const [groupData, setGroupData] = useState<GroupData>({id: -1, name: ''});
    const [groupUserData, setGroupUserData] = useState<MemberData[]>([]);
    const [paymentError, setPaymentError] = useState<string[]>([]);

    const getGroupStatement = async (): Promise<void> => {
        const groupData = await GroupController.getInstance().getGroupData(parseInt(id));
        setGroupData(groupData);

        const memberData = await GroupController.getInstance().getGroupMembers(parseInt(id));
        setGroupUserData(memberData);
    };

    const [paymentData, setPaymentData] = useState<PaymentData>({
        id: -1,
        shareParts: [],
        date: '',
        merchantName: '',
        payeeMember: appContext.user!,
        amount: ''
    });

    const addShareItem = () => {
        const share = paymentData.shareParts;
        const fraction = new BigNumber(1).div(share.length + 1).toFixed(40);
        share.forEach(paymentShare => paymentShare.share = fraction);
        share.push({memberId: -1, share: fraction});

        setPaymentData({...paymentData, shareParts: share})
    };

    const isFormValid = () => {
        const isPaymentDateValid = paymentData.date;
        const isAmountValid = new BigNumber(paymentData.amount).isGreaterThan(0);
        const isMerchantNameValid = paymentData.merchantName.trim().length > 0;
        const isPaymentSharesValid = paymentData.shareParts.length > 0 && paymentData.shareParts.filter(share => share.memberId <= 0).length === 0;

        const errorList: string[] = [];

        if (!isPaymentDateValid) errorList.push('Payment Date is not valid');
        if (!isAmountValid) errorList.push('Amount has to be greater than zero');
        if (!isMerchantNameValid) errorList.push('Merchant Name is not stated');
        if (!isPaymentSharesValid) errorList.push('Payment shares not valid');

        setPaymentError(errorList);

        return isPaymentDateValid && isAmountValid && isMerchantNameValid && isPaymentSharesValid;
    };

    useEffect(() => {
        getGroupStatement().finally()
    }, [id]);

    const PaymentShareItem = (index: { index: number }) => {
        const [paymentShare, setPaymentShare] = useState<PaymentShare>(paymentData.shareParts[index.index]);
        const updatePaymentShare = (updateShare: PaymentShare) => {
            setPaymentShare(updateShare);
            const shareParts = paymentData.shareParts;
            shareParts[index.index] = updateShare;
            setPaymentData({...paymentData, shareParts: shareParts});
        };

        const deleteShareItem = () => {
            const shareItemTemp = paymentData.shareParts;
            shareItemTemp.splice(index.index, 1);
            const fraction = new BigNumber(1).div(shareItemTemp.length).toFixed(40);
            shareItemTemp.forEach(paymentShare => paymentShare.share = fraction);
            setPaymentData({...paymentData, shareParts: shareItemTemp});
        };

        return (
            <tr>
                <td>
                    <select value={paymentShare.memberId} className="form-control"
                            onChange={event => updatePaymentShare({
                                ...paymentShare,
                                memberId: parseInt(event.target.value)
                            })}>
                        <option key={-1} value={-1}>{`<SELECT>`}</option>
                        {groupUserData.map((member, index) =>
                            <option key={index} value={member.id}>{member.name}</option>)}
                    </select>
                </td>
                <td>{new BigNumber(paymentShare.share).toFixed(2)}</td>
                <td>{new BigNumber(paymentData.amount || 0).times(paymentShare.share || 1).toFixed(2)}</td>
                <td>
                    <button type="button" className="btn-danger" onClick={() => deleteShareItem()}><DiffRemovedIcon
                        size={16}/></button>
                </td>
            </tr>);
    };

    const savePaymentData = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (isFormValid()) {
            await PaymentController.getInstance().savePaymentData(groupData.id, paymentData);
            history.push('/home');
        } else {
            event.preventDefault();
        }
    };

    return (
        <div className="container">
            <form>
                <div className="form-group">
                    <h2>Group Name: {groupData.name}</h2>
                </div>
                {paymentError.map(error => (
                    <div className='row alert-danger'>{error}</div>
                ))}
                <div className="form-group row">
                    <label htmlFor="merchantName">Merchant Name</label>
                    <input type="text" className="form-control" id="merchantName"
                           placeholder="Merchant Name"
                           onChange={(event => setPaymentData({...paymentData, merchantName: event.target.value}))}/>
                </div>
                <div className="form-group row">
                    <label htmlFor="date">Date</label>
                    <input type="date" className="form-control" id="date"
                           placeholder="Date"
                           onChange={(event => setPaymentData({...paymentData, date: event.target.value}))}/>
                </div>
                <div className="form-group row">
                    <label htmlFor="amount">Amount</label>
                    <input type="number" className="form-control" id="amount"
                           placeholder="Amount" step={'any'} min={0}
                           onChange={(event => setPaymentData({...paymentData, amount: event.target.value}))}/>
                </div>

                <div className="form-group row">
                    <label htmlFor="payeeMember">Paid by</label>
                    <select value={paymentData.payeeMember.id} className="form-control"
                            onChange={
                                event => setPaymentData(
                                    {...paymentData,
                                        payeeMember: groupUserData
                                            .filter(member=>member.id===parseInt(event.target.value))[0]})}>
                        {groupUserData.map((member, index) =>
                                <option key={index} value={member.id}>{member.name}</option>)}
                    </select>
                </div>
                <div className="form-group row">
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Share Member</th>
                            <th>Share fraction</th>
                            <th>Share value</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {paymentData.shareParts.map((share, index) => <PaymentShareItem
                            key={index} {...{index: index}}/>)}
                        </tbody>
                    </table>
                </div>
                <div className="form-group row">
                    <div className="col">
                        <button className="btn btn-primary" type="button" onClick={() => addShareItem()}>Add Share
                            Part
                        </button>
                        <button type="button" className="btn btn-primary" onClick={savePaymentData}>Submit Payment
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default PaymentPageComponent;
