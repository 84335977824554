import React, {useContext, useState} from "react";
import {Modal, Button, Container, Row, Col} from 'react-bootstrap';
import UserController, {MemberData} from "../services/UserController";
import GroupController from "../services/GroupController";
import {ApplicationContext} from "../ApplicationContext";

interface Props {
    groupId: number;
    groupName: string;
    onClose: (refresh: boolean) => void;
}

interface State {
    groupId: number;
    groupName: string;
}

const AddMemberModalComponent: React.FC<Props> = (props: Props) => {
    const appContext = useContext(ApplicationContext);
    const [state, setState] = useState<State>({...props});
    const [resultMemberList, setResultMemberList] = useState<MemberData[]>([]);
    const [selectedMemberIds, setSelectedMemberIds] = useState<number[]>([]);

    const handleConfirm = async () => {
        await inviteMembers();
        props.onClose(true);
    };

    const searchMember = async (searchText: string) => {
        if (searchText.length >= 3) {
            const searchMemberData = await UserController.getInstance().searchMember(searchText);
            setResultMemberList(searchMemberData);
        } else {
            setResultMemberList([]);
        }
    };

    const onMemberSelected = (memberId: number, checkedState: boolean) => {
        const tempSelectedMembers = Array.from(selectedMemberIds);
        if (checkedState) {
            tempSelectedMembers.push(memberId);
        } else {
            const memberIndex = tempSelectedMembers.indexOf(memberId, 0);
            if (memberIndex >= 0) {
                tempSelectedMembers.splice(memberIndex, 1);
            }
        }
        setSelectedMemberIds(tempSelectedMembers);
    };

    const inviteMembers = async () => {
        for (const selectedMember of selectedMemberIds) {
            await GroupController.getInstance().sendInvitation(state.groupId, selectedMember, appContext.user!.id);
        }
    };

    return (
        <Modal show={true} onHide={() => props.onClose(false)}>
            <Modal.Header closeButton={true}>
                <Modal.Title>Add Members to Group {state.groupName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                            <span className="input-group-text"
                                                  id="inputGroup-sizing-default">Search</span>
                            </div>
                            <input type="text" className="form-control" placeholder='Search for members'
                                   aria-describedby="inputGroup-sizing-default"
                                   onChange={event => searchMember(event.target.value)}/>
                            <div className="input-group-append">
                                <span className="input-group-text">Found ({resultMemberList.length})</span>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        {
                            resultMemberList.length > 0 ?
                                <Col>
                                    {
                                        resultMemberList.map((member, index) =>
                                            <div className="input-group mb-3" key={index}>
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <input key={member.id} type="checkbox"
                                                               onChange={(event => onMemberSelected(member.id, event.target.checked))}
                                                        />
                                                    </div>
                                                </div>
                                                <label
                                                    className="form-control justify-content-start">{member.name}</label>
                                            </div>
                                        )
                                    }
                                </Col> : <div></div>
                        }
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-secondary" onClick={() => props.onClose(false)}>Cancel</Button>
                <Button className="btn btn-primary" onClick={() => handleConfirm()}
                        disabled={selectedMemberIds.length === 0}>Confirm {selectedMemberIds.length > 0 &&
                <span>[{selectedMemberIds.length}]</span>}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddMemberModalComponent;
