import React, {useContext, useEffect, useState} from "react";
import GroupController, {GroupSummaryData} from "../services/GroupController";
import BigNumber from 'bignumber.js'
import {Link} from 'react-router-dom';
import {ApplicationContext} from "../ApplicationContext";
import {CheckIcon} from "@primer/octicons-react";
import AddMemberModalComponent from "./AddMemberModalComponent";
import {DiffRemovedIcon, PencilIcon} from "@primer/octicons-react";
import ConfirmationDialog from "./ConfirmationDialog";

interface State {
    groupData: GroupSummaryData;
    showAddMemberModalComponent: boolean;
}

const GroupCard: React.FC<GroupSummaryData> = (props: GroupSummaryData) => {
    const appContext = useContext(ApplicationContext);
    const [groupSummaryData, setGroupSummaryData] = useState<GroupSummaryData>(props);
    const [showDeleteGroupConfirmation, setShowDeleteGroupConfirmation] = useState<boolean>(false);
    const numberOfMembers = groupSummaryData.members.length;
    const totalGroupExpenses = groupSummaryData.payments.reduce((accumulator, payment) => accumulator.plus(payment.amount), new BigNumber(0));
    const cardMode = groupSummaryData.groupId ? 'active' : 'edit';

    const [state, setState] = useState<State>({groupData: props, showAddMemberModalComponent: false});

    const [groupName, setGroupName] = useState<string>('');
    const saveGroupName = async () => {
        const camelizeGroupName = groupName.replace(/\w\S*/g, (substring => substring.charAt(0).toUpperCase() + substring.substr(1).toLowerCase()));
        const saveGroupSummaryData = await GroupController.getInstance().saveGroupName(camelizeGroupName, appContext.user?.id!);
        setGroupSummaryData(saveGroupSummaryData)
    };

    const deleteGroup = async () => {
        try {
            await GroupController.getInstance().deleteGroup(groupSummaryData.groupId!, appContext.user!.id);
            appContext.loadCurrentUser();
        } catch (error) {
            console.error(error.message);
        } finally {
            setShowDeleteGroupConfirmation(false);
        }
    };

    useEffect(() => {
        setGroupSummaryData(props);
    }, []);

    return (
        <div className="card w-50">
            <div className="card-header">
                {cardMode === 'active' ?
                    <div className='row'>
                        <Link className='col' to={`/group-statement/${groupSummaryData.groupId}`}>
                            {groupSummaryData.groupName}
                        </Link>
                        <span>
                            {(groupSummaryData.createdByUserId === appContext.user?.id) &&
                            <span className="btn btn-outline-danger"
                                  onClick={() => setShowDeleteGroupConfirmation(true)}>
                                <DiffRemovedIcon size={16}/>
                            </span>}
                            <span className="btn btn-outline-secondary"><PencilIcon size={16}/></span>
                        </span>
                    </div> :
                    <div>
                        <input type='text' onChange={(event) => setGroupName(event.target.value)}/>
                        <span className="btn btn-success" onClick={() => saveGroupName()}><i><CheckIcon size={12}/></i></span>
                    </div>
                }
            </div>
            <ul className="list-group list-group-flush">
                <li className="list-group-item">Total Expenses £{totalGroupExpenses.toFixed(2)}</li>
                <li className="list-group-item">Members [{numberOfMembers}]</li>
            </ul>
            <div className='card-footer'>
                <div className='row'>
                    <div className='col'>
                        <div className='btn btn-primary'
                             onClick={() => setState({...state, showAddMemberModalComponent: true})}>Add Member
                        </div>
                    </div>
                    <div className='col'>
                        <Link to={`/group/${groupSummaryData.groupId}/payment`}>
                            <span className='btn btn-primary'>Add Expenses</span>
                        </Link>
                    </div>
                </div>
            </div>
            {showDeleteGroupConfirmation && <ConfirmationDialog title={`Delete Group: ${groupSummaryData.groupName}`}
                                                                confirmationMessage={'Are you sure?'}
                                                                confirmButtonAction={deleteGroup}
                                                                cancelAction={() => setShowDeleteGroupConfirmation(false)}/>}
            {state.showAddMemberModalComponent && <AddMemberModalComponent groupId={groupSummaryData.groupId!}
                                                                           groupName={groupSummaryData.groupName}
                                                                           onClose={(refresh: boolean) => {
                                                                               setState({
                                                                                   ...state,
                                                                                   showAddMemberModalComponent: false
                                                                               });
                                                                           }} key={groupSummaryData.groupId}/>}
        </div>
    );
};

export default GroupCard;

