import {MemberData} from "./UserController";

export interface PaymentData {
    id: number;
    merchantName: string;
    amount: string;
    date: string;
    shareParts: PaymentShare[];
    payeeMember: MemberData;
}

export interface PaymentShare {
    memberId: number;
    share: string;
}

export default class PaymentController {
    private constructor() {
    }

    private static instance: PaymentController;

    public static getInstance(): PaymentController {
        if (!this.instance) {
            this.instance = new PaymentController();
        }
        return this.instance;
    }

    public async savePaymentData(groupId: number, paymentData: PaymentData): Promise<number> {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify(paymentData)
        };

        // @ts-ignore
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/holiday-group/${groupId}/payment`, requestOptions);
        const body: { id: number } = await response.json();
        return body.id;
    }

    public async deletePaymentData(paymentId: number): Promise<void> {
        const requestOptions = {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        };

        // @ts-ignore
        await fetch(`${process.env.REACT_APP_API_URL}/api/payment/${paymentId}`, requestOptions);
    }
}
