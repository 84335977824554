import React, {useContext, useEffect, useState} from "react";
import GroupCard from "./GroupCard";
import GroupController, {GroupSummaryData} from "../services/GroupController";
import {ApplicationContext} from "../ApplicationContext";

const HomePageComponent = () => {
    const applicationContext = useContext(ApplicationContext);
    const [groupList, setGroupList] = useState<GroupSummaryData[]>([]);

    const loanGroupList = async () => {
        const groupData = await GroupController.getInstance().getGroupSummaryList(applicationContext.user!.id);
        setGroupList(groupData || []);
    };

    const addNewGroup = () => {
        const newEmptyGroup: GroupSummaryData = {
            groupName: '',
            members: [],
            payments: []
        };

        setGroupList([...groupList, newEmptyGroup])
    };

    useEffect(() => {
        loanGroupList();
    }, [applicationContext]);

    return <div className="container">
        <ul className="row navbar-nav">
            <li className="nav-item">
                <div className="btn btn-primary" onClick={() => addNewGroup()}>Add New Group</div>
            </li>
        </ul>
        <div className='row'>
            {
                groupList.map((group, index) => <GroupCard key={index} {...group} />)
            }
        </div>
    </div>
};

export default HomePageComponent;
