import React, {useContext, useEffect, useState} from "react";
import {DiffAddedIcon, BellIcon, CheckCircleFillIcon, XCircleFillIcon} from '@primer/octicons-react'
import {Link} from 'react-router-dom';
import {ApplicationContext} from "../ApplicationContext";
import UserController, {InvitationData} from "../services/UserController";

const Navigation = () => {
    const applicationContext = useContext(ApplicationContext);
    const [invitations, setInvitations] = useState<InvitationData[]>([]);

    const loadInvitations = () => {
        if (applicationContext.user) {
            UserController.getInstance().getInvitations(applicationContext.user.id).then(invitation => setInvitations(invitation));
        }
    };

    const actionInvitation = async (invitationId: number, status: boolean) => {
        await UserController.getInstance().actionInvitation(invitationId, status ? 'accept' : 'decline');
        loadInvitations();
        applicationContext.loadCurrentUser();
    }

    const logoutUserHandler = async () => {
        await UserController.getInstance().logoutUser();
        applicationContext.loadCurrentUser();
    };

    useEffect(() => loadInvitations(), [applicationContext])

    return <nav className="navbar navbar-expand-sm navbar-light bg-light">
        <Link className="navbar-brand" to={'/home'}>Holiday Splitter</Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        {applicationContext.user ?
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                        {invitations.length > 0 && <div className='nav-link dropdown-toggle'
                                                        id="invitationDropDown" role="button"
                                                        data-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                            <BellIcon
                                data-toggle="dropdown" aria-haspopup="true"
                                size={16}/> Invitations
                        </div>}
                        {invitations.map(invitation =>
                            <div className="dropdown-menu" aria-labelledby="invitationDropDown">
                                <div><p className="dropdown-item">Invitation
                                    From: {invitation.requestedMemberName} to Group:{invitation.groupName}</p>
                                    <button type="button" className="btn btn-primary col-sm-2"
                                            onClick={() => actionInvitation(invitation.id, true)}><CheckCircleFillIcon
                                        size={16}/></button>
                                    <button type="button" className="btn btn-danger col-sm-2"
                                            onClick={() => actionInvitation(invitation.id, false)}><XCircleFillIcon
                                        size={16}/></button>
                                </div>
                            </div>)}
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {applicationContext.user.name}
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a className="dropdown-item" onClick={() => logoutUserHandler()}>Logout</a>
                        </div>
                    </li>
                </ul>
            </div> : <div></div>
        }
    </nav>
};

export default Navigation;
