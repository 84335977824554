import React from "react";

const ProfilePageComponent = () => {
    return (
        <form>
            <div className="form-group">
                <label htmlFor="name">Member Name</label>
                <input type="text" className="form-control" id="name"
                       placeholder="Member Name"/>
            </div>
            <div className="form-group row">
                <div className="col-sm-2">
                    <button type="button" className="btn btn-primary">Save</button>
                </div>
            </div>
        </form>
    );
};

export default ProfilePageComponent;
