import React, {useContext, useState} from "react";
import {Link} from 'react-router-dom';
import UserController from "../services/UserController";
import {ApplicationContext} from "../ApplicationContext";

const UserLogInComponent = () => {
    const applicationContext = useContext(ApplicationContext);
    const [userLoginData, setLoginData] = useState<{ username: string, password: string }>({
        username: '',
        password: ''
    });

    const [loginError, setLoginError] = useState<string>();

    const loginUserHandler = async () => {
        const userLoginState = await UserController.getInstance().loginUserHandler(userLoginData.username, userLoginData.password);
        if (!userLoginState.status) {
            setLoginError(userLoginState.error);
        } else {
            applicationContext.loadCurrentUser();
        }
    };

    return <div id="login">
        <div className="container">
            <div id="login-row" className="row justify-content-center align-items-center">
                <div id="login-column" className="col-md-6">
                    <div id="login-box" className="col-md-12">
                        <form id="login-form" className="form" action="" method="post">
                            <h3 className="text-center text-secondary">Member Login</h3>
                            <div className='alert-danger'>{loginError}</div>
                            <div className="form-group">
                                <label htmlFor="username" className="text-primary">Username</label><br/>
                                <input type="text" name="username" id="username" className="form-control"
                                       onChange={(event) => setLoginData({
                                           ...userLoginData,
                                           username: event.target.value
                                       })}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password" className="text-primary">Password</label><br/>
                                <input type="password" name="password" id="password" className="form-control"
                                       onChange={(event) => setLoginData({
                                           ...userLoginData,
                                           password: event.target.value
                                       })}

                                       onKeyUp={async (event) => {
                                           if (event.key === 'Enter') {
                                               await loginUserHandler();
                                           }
                                       }}
                                />
                            </div>
                            <div className="row justify-content-center align-items-center">
                                <div className='col-4 btn btn-primary' onClick={() => loginUserHandler()}>Sign In</div>
                                <Link to={'/register'} className='col-4 btn btn-primary'>Register Me</Link>
                            </div>
                            <hr/>
                            {/*<div className='row justify-content-center align-items-center'>*/}
                            {/*    <div className='col-8 btn btn-primary'>Login with Facebook</div>*/}
                            {/*</div>*/}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default UserLogInComponent;
