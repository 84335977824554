import React, {useContext, useState} from "react";
import UserController, {UserData} from "../services/UserController";
import {ApplicationContext} from "../ApplicationContext";

const UserRegistrationComponent = () => {
    const context = useContext(ApplicationContext);
    const [userRegData, setUserRegData] = useState<UserData>({displayName: '', password: '', username: ''});
    const [confirmedPassword, setConfirmPassword] = useState<string>('');
    const registerMember = async () => {
        await UserController.getInstance().registerUser(userRegData);
        context.loadCurrentUser();
    };

    const isValidPassword = (): boolean => userRegData.password.length > 0 && confirmedPassword.length > 0 && userRegData.password === confirmedPassword;
    const isFormValid = (): boolean => isValidPassword() && userRegData.username.length > 0 && userRegData.displayName.length > 0;

    return (
        <div id="login">
            <div className="container">
                <div id="login-row" className="row justify-content-center align-items-center">
                    <div id="login-column" className="col-md-6">
                        <div id="login-box" className="col-md-12">
                            <form id="login-form" className="form" action="" method="post">
                                <h3 className="text-center text-secondary">New Member Registration</h3>
                                <div className="form-group">
                                    <label htmlFor="displayName" className="text-primary">Display Name</label><br/>
                                    <input type="text" name="displayName" id="displayName" className="form-control"
                                           onChange={(event) => {
                                               setUserRegData({...userRegData, displayName: event.target.value})
                                           }}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="username" className="text-primary">Username</label><br/>
                                    <input type="text" name="username" id="username" className="form-control"
                                           onChange={(event) => {
                                               setUserRegData({...userRegData, username: event.target.value})
                                           }}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password" className="text-primary">Password</label>
                                    <input type="password" name="password" id="password" className="form-control"
                                           onChange={(event) => {
                                               setUserRegData({...userRegData, password: event.target.value})
                                           }}/><br/>
                                    <label htmlFor="passwordConfirm" className="text-primary">Confirm Password</label>
                                    <input type="password" name="passwordConfirm" id="passwordConfirm"
                                           className="form-control"
                                           onChange={(event) => {
                                               setConfirmPassword(event.target.value)
                                           }}/>
                                </div>
                                <div>
                                    <button type="button" name="submit"
                                            className={isFormValid() ? 'btn btn-primary btn-md' : 'btn btn-primary btn-md disabled'}
                                            onClick={() => registerMember()}>
                                        Save and Sign In
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserRegistrationComponent;
