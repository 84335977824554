import {MemberData} from "./UserController";
import {PaymentData} from "./PaymentController";
import BigNumber from 'bignumber.js';

export default class GroupController {
    private constructor() {
    }

    private static instance: GroupController;

    public static getInstance(): GroupController {
        if (!this.instance) {
            this.instance = new GroupController();
        }
        return this.instance;
    }

    public async getGroupMembers(groupId: number): Promise<MemberData[]> {
        const groupSummaryDate = await this.getGroupStatement(groupId);
        return groupSummaryDate.members;
    }

    public async getGroupData(groupId: number): Promise<GroupData> {
        const requestOptions = {
            method: 'GET',
            credentials: 'include'
        };
        // @ts-ignore
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/holiday-group/${groupId}`, requestOptions);
        const body: { id: number, groupName: string } = await response.json();
        if (body) {
            return {id: body.id, name: body.groupName}
        }
        throw new Error('Invalid group Id');
    }

    public async getGroupStatement(groupId: number): Promise<GroupSummaryData> {
        const requestOptions = {
            method: 'GET',
            credentials: 'include'
        };
        // @ts-ignore
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/holiday-group/${groupId}/summary`, requestOptions);
        const body: { id: number, groupName: string, members: MemberData[], payments: PaymentData[], createdByUser: number } = await response.json();
        if (body) {
            const groupSummary: GroupSummaryData = {
                groupId: body.id,
                groupName: body.groupName,
                members: body.members,
                payments: body.payments.map(payment => getCalculatedPaymentShare(payment)),
                createdByUserId: body.createdByUser
            };
            return groupSummary;
        }
        throw new Error('Invalid group Id');
    }

    public async sendInvitation(groupId: number, memberId: number, requestedId: number): Promise<void> {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify({inviteeId: memberId, requestedId: requestedId})
        };

        // @ts-ignore
        await fetch(`${process.env.REACT_APP_API_URL}/api/holiday-group/${groupId}/invite-member`, requestOptions);
    }

    public async getGroupSummaryList(memberId: number): Promise<GroupSummaryData[]> {
        const requestOptions = {
            method: 'GET',
            credentials: 'include'
        };
        // @ts-ignore
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/member/${memberId}/group-summary`, requestOptions);
        const body = await response.json();
        if (body) {
            const groupSummaryList: GroupSummaryData[] = [];
            body.forEach((item: { id: number, groupName: string, members: MemberData[], payments: PaymentData[], createdByUser: number }) => groupSummaryList.push({
                groupId: item.id,
                groupName: item.groupName,
                members: item.members,
                payments: item.payments.map(payment => getCalculatedPaymentShare(payment)),
                createdByUserId: item.createdByUser
            }));

            return groupSummaryList;
        }
        return [];
    }

    public async deleteGroup(groupId: number, deletedUserId: number): Promise<void> {
        const requestOptions = {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify({deletedUserId: deletedUserId})
        };

            // @ts-ignore
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/holiday-group/${groupId}`, requestOptions);
            if(response.status === 400){
                const body: {error: string} = await response.json();
                throw new Error(body.error);
            }
    }

    public async saveGroupName(groupName: string, createdUserId: number): Promise<GroupSummaryData> {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify({groupName: groupName, createdMemberId: createdUserId})
        };

        // @ts-ignore
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/holiday-group`, requestOptions);
        const body: { id: number, groupName: string } = await response.json();
        return this.getGroupStatement(body.id);
    }
}

const getCalculatedPaymentShare = (paymentData: PaymentData): CalculatedPaymentShare => {
    const result: CalculatedPaymentShare = {
        paymentId: paymentData.id,
        amount: paymentData.amount,
        date: paymentData.date,
        merchantName: paymentData.merchantName,
        payeeMember: paymentData.payeeMember,
        calculatedShareAmount: paymentData.shareParts.reduce((map, data) => {
            const memberAmount = map.get(data.memberId) || 0;
            const amount = new BigNumber(paymentData.amount).multipliedBy(data.share).plus(memberAmount).toFixed(20);
            return map.set(data.memberId, amount)
        }, new Map<number, string>())
    };

    return result;
};

export interface GroupData {
    id: number;
    name: string;
}

export interface GroupSummaryData {
    groupId?: number;
    groupName: string;
    members: MemberData[];
    payments: CalculatedPaymentShare[];
    createdByUserId?: number;
}

export interface CalculatedPaymentShare {
    paymentId: number;
    merchantName: string;
    amount: string;
    date: string;
    payeeMember: MemberData;
    calculatedShareAmount: Map<number, string>;
}
