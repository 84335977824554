export default class UserController {
    private constructor() {
    }

    private static instance: UserController;

    public static getInstance(): UserController {
        if (!this.instance) {
            this.instance = new UserController();
        }
        return this.instance;
    }

    public async getLoggedInUser(): Promise<MemberData | null> {
        const requestOptions = {
            method: 'GET',
            credentials: 'include'
        };
        // @ts-ignore3
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/whoami`, requestOptions);
        const body = await response.json();
        if (body.user) {
            return {id: body.user.id, name: body.user.name}
        }
        return null;
    }

    public async searchMember(username: string): Promise<MemberData[]> {
        const requestOptions = {
            method: 'GET',
            credentials: 'include'
        };
        // @ts-ignore
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/member/search/${username}`, requestOptions);
        const body = await response.json();
        if (body.data) {
            return body.data;
        }
        return [];
    }

    public async actionInvitation(invitationId: number, action: string): Promise<void> {
        const requestOptions = {
            method: 'PUT',
            credentials: 'include'
        };
        // @ts-ignore
        await fetch(`${process.env.REACT_APP_API_URL}/api/invitation/${invitationId}/${action}`, requestOptions);
    }

    public async getInvitations(memberId: number): Promise<InvitationData[]> {
        const requestOptions = {
            method: 'GET',
            credentials: 'include'
        };
        // @ts-ignore
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/member/${memberId}/invitations`, requestOptions);
        const body = await response.json();
        if (body.data) {
            return body.data.map((invitation: {
                invitationId: number,
                requestedName: string,
                groupName: string,
                acceptedStatus: boolean
            }) => {
                return {
                    id: invitation.invitationId,
                    requestedMemberName: invitation.requestedName,
                    acceptedStatus: invitation.acceptedStatus,
                    groupName: invitation.groupName
                }
            })
        }
        return [];
        // return [{
        //     id: 1,
        //     requestedMemberName: 'Alan',
        //     acceptedStatus: false,
        //     groupName: 'YorkStaycation'
        // }];
    }

    public async registerUser(userData: UserData): Promise<{ status: boolean, error?: string }> {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify({username: userData.username, password: userData.password, name: userData.displayName})
        };

        // @ts-ignore
        const data = await fetch(`${process.env.REACT_APP_API_URL}/api/member-registration`, requestOptions);
        if (data.status === 200) {
            return {status: true};
        }
        return {status: false, error: data.statusText};
    }

    public async logoutUser(): Promise<boolean> {
        const requestOptions = {
            method: 'GET',
            credentials: 'include'
        };
        // @ts-ignore
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/logout`, requestOptions);
        const body = await response.json();
        return body;
    }

    public async loginUserHandler(username: string, password: string): Promise<{ status: boolean, error?: string }> {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify({username, password})
        };

        // @ts-ignore
        const data = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/login`, requestOptions);
        if (data.status === 200) {
            return {status: true};
        }
        return {status: false, error: data.statusText};
    }
}

export interface UserData {
    displayName: string;
    username: string;
    password: string;
}

export interface MemberData {
    id: number;
    name: string;
}

export interface InvitationData {
    id: number;
    requestedMemberName: string;
    groupName: string;
    acceptedStatus: boolean;
}
