import React, {useEffect, useState} from 'react';
import './App.css';
import Navigation from "./components/Navigation";
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import HomePageComponent from "./components/HomePageComponent";
import PaymentPageComponent from "./components/PaymentPageComponent";
import ProfilePageComponent from "./components/ProfilePageComponent";
import {AppContextData, ApplicationContext} from "./ApplicationContext";
import UserController, {MemberData} from "./services/UserController";
import GroupStatementComponent from "./components/GroupStatementComponent";
import UserLogInComponent from "./components/UserLogInComponent";
import UserRegistrationComponent from "./components/UserRegistrationComponent";

type SetState = React.Dispatch<React.SetStateAction<State>>;

interface State {
    user?: MemberData | null;
    isLoading: boolean;
}

const loadCurrentUser = async (state: State, setUserData: SetState) => {
    setUserData({isLoading: true});

    const memberData = await UserController.getInstance().getLoggedInUser();

    setUserData({user: memberData, isLoading: true});
};

const App = () => {
    const [state, setState] = useState<State>({isLoading: true});

    const appConValue: AppContextData = {
        user: state.user,
        loadCurrentUser: async () => await loadCurrentUser(state, setState),
    };

    useEffect(() => {
        loadCurrentUser(state, setState);
    }, []);

    return (
        <BrowserRouter>
            <ApplicationContext.Provider value={appConValue}>
                <div className="App">
                    <Navigation/>
                    {state.user ?
                        <Switch>
                            <Route path={'/home'} component={HomePageComponent}/>
                            <Route path={'/group/:id/payment'} component={PaymentPageComponent}/>
                            <Route path={'/profile'} component={ProfilePageComponent}/>
                            <Route path={'/group-statement/:id'} component={GroupStatementComponent}/>
                            <Route path={'*'}>
                                <Redirect to={'/home'}/>
                            </Route>
                        </Switch>
                        :
                        <Switch>
                            <Route path={'/login'} component={UserLogInComponent}/>
                            <Route path={'/register'} component={UserRegistrationComponent}/>
                            <Route path={'*'}>
                                <Redirect to={'/login'}/>
                            </Route>
                        </Switch>
                    }

                </div>
            </ApplicationContext.Provider>
        </BrowserRouter>
    );
};

export default App;
