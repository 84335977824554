import React from "react";
import {MemberData} from "./services/UserController";

export interface AppContextData {
    user?: MemberData | null;
    loadCurrentUser: () => void;
}

export const ApplicationContext = React.createContext<AppContextData>({
    loadCurrentUser: () => {
    }
});
