import React, {useState} from "react";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";

interface Props {
    title: string,
    confirmationMessage: string;
    confirmButtonAction: () => Promise<void>;
    cancelAction: () => void;
}

const ConfirmationDialog: React.FC<Props> = (props: Props) => {
    return (
        <Modal show={true} onHide={props.cancelAction}>
            <Modal.Header closeButton={true}>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <div>{props.confirmationMessage}</div>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-secondary" onClick={props.cancelAction}>Cancel</Button>
                <Button className="btn btn-primary" onClick={props.confirmButtonAction}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationDialog;
