import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import GroupController, {CalculatedPaymentShare, GroupSummaryData} from "../services/GroupController";
import BigNumber from 'bignumber.js';
import {DiffRemovedIcon} from "@primer/octicons-react";
import PaymentController from "../services/PaymentController";
import ConfirmationDialog from "./ConfirmationDialog";

interface GroupStatementParams {
    id: string;
}

const GroupStatementComponent = () => {
    const {id} = useParams<GroupStatementParams>();
    const [groupStatement, setGroupStatement] = useState<GroupSummaryData>();
    const [showPaymentDeleteComponent, setShowPaymentDeleteComponent] = useState<{ payment?: CalculatedPaymentShare, visible: boolean }>({visible: false});

    const getGroupStatement = async (): Promise<void> => {
        const groupData = await GroupController.getInstance().getGroupStatement(parseInt(id));
        setGroupStatement(groupData);
    };

    const deletePayment = async (paymentId: number): Promise<void> => {
        await PaymentController.getInstance().deletePaymentData(paymentId);
        await getGroupStatement();
        setShowPaymentDeleteComponent({visible: false});
    };

    useEffect(() => {
        getGroupStatement().finally()
    }, [id]);

    const totalExpensesMap = new Map<number, BigNumber>();
    const totalContributionMap = new Map<number, BigNumber>();
    const emptyValue = new BigNumber(0).toFixed(2);

    return (
        <div>
            <h3>Group Account Statement: {groupStatement?.groupName}</h3>
            <table className="table">
                <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Payee Member</th>
                    <th scope="col">Merchant</th>
                    <th scope="col">Amount</th>
                    {groupStatement?.members.map(member => <th scope="col" key={member.id}>{member.name}</th>)}
                </tr>
                </thead>
                <tbody>
                {groupStatement?.payments.map(payment => {
                        let contribution = totalContributionMap.get(payment.payeeMember.id);
                        if (!contribution) {
                            contribution = new BigNumber(0);
                        }
                        contribution = contribution.plus(payment.amount);
                        totalContributionMap.set(payment.payeeMember.id, contribution);

                        return <tr key={payment.paymentId}>
                            <td><span className={'btn btn-danger'}
                                      onClick={() => setShowPaymentDeleteComponent({
                                          payment: payment,
                                          visible: true
                                      })}><DiffRemovedIcon size={16}/></span></td>
                            <td>{payment.payeeMember.name}</td>
                            <td>{payment.merchantName}</td>
                            <td>{new BigNumber(payment.amount).toFixed(2)}</td>
                            {groupStatement?.members.map(member => {
                                    const memberPartValue = payment.calculatedShareAmount.get(member.id) || 0;
                                    let expenses = totalExpensesMap.get(member.id);
                                    if (!expenses) {
                                        expenses = new BigNumber(0);
                                    }
                                    expenses = expenses.plus(memberPartValue);
                                    totalExpensesMap.set(member.id, expenses);
                                    return <td scope="col"
                                               key={member.id}>{new BigNumber(memberPartValue).toFixed(2) || emptyValue}</td>
                                }
                            )}
                        </tr>
                    }
                )}
                <tr>
                    <th scope="row" colSpan={4} className="text-right">Expenses</th>
                    {groupStatement?.members.map(member =>
                        <td key={member.id}>{totalExpensesMap.get(member.id)?.toFixed(2) || emptyValue}</td>)}
                </tr>
                <tr>
                    <th scope="row" colSpan={4} className="text-right">Total Contributed</th>
                    {groupStatement?.members.map(member =>
                        <td key={member.id}>{totalContributionMap.get(member.id)?.toFixed(2) || emptyValue}</td>)}
                </tr>
                <tr>
                    <th scope="row" colSpan={4} className="text-right">Total Owed</th>
                    {groupStatement?.members.map(member => {
                            const totalContribution = new BigNumber(totalContributionMap.get(member.id) || 0);
                            const totalExpenses = totalExpensesMap.get(member.id) || 0;
                            return <td
                                key={member.id}>{totalContribution.minus(totalExpenses).toFixed(2) || emptyValue}</td>
                        }
                    )}
                </tr>
                </tbody>
            </table>
            {showPaymentDeleteComponent.visible && <ConfirmationDialog
                title={'Delete Payment'}
                confirmationMessage={`Are you sure to delete payment made by ${showPaymentDeleteComponent.payment!.payeeMember.name} for £${showPaymentDeleteComponent.payment!.amount}`}
                cancelAction={() => setShowPaymentDeleteComponent({visible: false})}
                confirmButtonAction={async () => {
                    await deletePayment(showPaymentDeleteComponent.payment!.paymentId)
                }}/>}
        </div>
    );
};

export default GroupStatementComponent;
